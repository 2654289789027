
import {computed, defineComponent, onMounted, onUpdated, ref} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
//import Loading from "@/components/Loading.vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ProfileOverview",
  props: {
    profile: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const temp = localStorage.getItem("user") as any;
    const user = JSON.parse(temp);
    const permissions = computed(() => store.getters.permissions);
    const canReadCompaniesList = computed(() => {
      return permissions.value.filter((x) => x.name.includes("offices") || x.name.includes("companies")).length > 0;
    });

    onMounted(() => {
      store.commit("setLoadingStatus", true);
    });

    return {
      canReadCompaniesList,
      user,
    };
  },
  //components: { Loading },
});
