import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "kt_profile_details_view",
  class: "card mb-5 mb-xl-10"
}
const _hoisted_2 = { class: "card-header cursor-pointer" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0 text-uppercase" }
const _hoisted_5 = { class: "card-body p-9" }
const _hoisted_6 = { class: "row mb-7" }
const _hoisted_7 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_8 = { class: "col-lg-8" }
const _hoisted_9 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_10 = {
  key: 0,
  class: "row mb-7"
}
const _hoisted_11 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_12 = { class: "col-lg-8 fv-row" }
const _hoisted_13 = { class: "row mb-7" }
const _hoisted_14 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_15 = ["title"]
const _hoisted_16 = { class: "col-lg-8 d-flex align-items-center" }
const _hoisted_17 = { class: "fw-bolder fs-6 me-2" }
const _hoisted_18 = { class: "badge badge-success" }
const _hoisted_19 = { class: "row mb-7" }
const _hoisted_20 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_21 = {
  key: 0,
  class: "col-lg-8"
}
const _hoisted_22 = {
  href: "#",
  class: "fw-bold fs-6 text-dark text-hover-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("profileDetails")), 1)
      ]),
      _createVNode(_component_router_link, {
        to: `/user/${_ctx.profile.id}/settings`,
        class: "btn btn-primary align-self-center"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("editProfile")), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("ifullname")), 1),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.profile.name), 1)
        ])
      ]),
      (_ctx.canReadCompaniesList)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("companies")), 1),
            _createElementVNode("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.companies, (elementData, elementKey) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: elementKey,
                  class: "fw-bold fs-6"
                }, _toDisplayString(elementData.name), 1))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("label", _hoisted_14, [
          _createTextVNode(_toDisplayString(_ctx.$t("emailContact")) + " ", 1),
          _createElementVNode("i", {
            class: "fas fa-exclamation-circle ms-1 fs-7",
            "data-bs-toggle": "tooltip",
            title: _ctx.$t('phoneValidation')
          }, null, 8, _hoisted_15)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.profile.email), 1),
          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("verified")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t("role")), 1),
        (_ctx.profile.roles && _ctx.profile.roles.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _createElementVNode("a", _hoisted_22, _toDisplayString(_ctx.profile.roles[0].name), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}